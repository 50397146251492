<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-methods="searchData"
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card no-body>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
          small
        >
          <template #cell(order_number)="data">
            <b-button
              variant="flat-primary"
              size="sm"
              :to="'/i2sales/view/' + data.item.id"
            >
              {{ data.item.order_number }}
            </b-button>
          </template>
          <template #cell(customer_name)="data">
            {{ data.item.customer_name }}
            <div v-if="data.item.company_name">
              <small class="text-warning font-small-1">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(swap_brand)="data">
            {{ data.item.swap_brand }} {{ data.item.swap_model }}
            <div class="text-danger font-small-1">
              {{ data.item.chasis }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge :variant="data.item.status === '1' ? 'success' : 'warning'">
                <div v-if="data.item.status === '1'">
                  Onaylandı
                </div>
                <div v-if="data.item.status === '2'">
                  Onay Bekliyor
                </div>
                <div v-if="data.item.status === '3'">
                  Hazırlanıyor
                </div>
              </b-badge>
            </div>
          </template>
          <template #cell(invoice_date)="data">
            {{ data.item.invoice_date? moment(data.item.invoice_date).format('DD.MM.YYYY') : '-' }}
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'
import PublicFilter from '@/views/I2Sales/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    PublicFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'order_number',
          label: 'No',
          thStyle: { width: '70px', verticalAlign: 'middle', padding: '10px' },
        },
        {
          key: 'code',
          label: 'A KOD',
          thStyle: { width: '70px', verticalAlign: 'middle', padding: '10px' },
        },
        {
          key: 'customer_name',
          label: 'Müşteri',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'swap_brand',
          label: 'Araç Bilgisi',
          thStyle: { verticalAlign: 'middle', width: '200px' },
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '350px', verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '90px', verticalAlign: 'middle' },
        },
        {
          key: 'invoice_date',
          label: 'Fatura Tarihi',
          thStyle: { width: '160px', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_i2sale.id AS id',
          'com_i2sale.order_number AS order_number',
          'com_i2stock.code AS code',
          'com_customer.name AS customer_name',
          'com_customer.company_name AS company_name',
          'com_swap_brand.name AS swap_brand',
          'com_swap_model.title AS swap_model',
          'com_i2stock.chasis AS chasis',
          'com_user.name AS username',
          'com_i2sale.status AS status',
          '_mssql_600_aracpark.satis_tarihi AS invoice_date',
        ],
        order_by: ['id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['i2sales/getI2Sales']
    },
    dataCount() {
      return this.$store.getters['i2sales/getI2SalesCount']
    },
    quickSearch() {
      return this.$store.getters['i2sales/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_i2sale.order_number': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_i2stock.chasis': this.quickSearch.keyword,
          'com_i2stock.code': this.quickSearch.keyword,
        }
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.getDataList()
    },
    filterData(params) {
      const where = {}
      if (params.id_com_swap_brand) {
        where['com_i2stock.id_com_swap_brand'] = params.id_com_swap_brand
      }
      if (params.status !== null) {
        where['com_i2sale.status'] = params.status
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('i2sales/i2Sales', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
